import React, { useEffect } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import NavHeader from '@transverse/nav-header'
import { DidomiSDK } from '@didomi/react'
import { DIDOMI_API_KEY } from '@/shared/constants'

import '@transverse/nav-header/dist/index.cjs.css'
import '../styles/globals.scss'
import ContextProvider from '@/shared/contexts/ContextProvider'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import Layout from '@/components/templates/Shared/Layout/Layout'
import { helmet } from '@/data/helmet'
import { initAPM } from '@/utils/elasticAPM'
import { configurationsService } from '@/shared/ConfigurationService'
import PlausibleProvider from 'next-plausible'

const App = ({ Component, pageProps }) => {
  const Footer: any = dynamic(() => import('@transverse/footer'), { ssr: false })
  const { isMobile, isTablet } = useBreakpoints()
  const { metas, canonicalLink } = helmet

  useEffect(() => {
    initAPM()
  }, [])

  const domain = typeof window !== 'undefined' ? window.document.location.hostname : 'transfert.free.fr'

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <script type="text/javascript" src="/env-config.js" />
        <title>Free Transfert - Service d'envoi de fichiers</title>
        {metas && metas.map((meta, index) => <meta {...meta} key={`meta-tag-${index}`} />)}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      </Head>
      <section className="w-full">
        {typeof window !== 'undefined' && <DidomiSDK apiKey={DIDOMI_API_KEY} iabVersion={1} gdprAppliesGlobally />}
        <NavHeader
          theme="light"
          project="free-transfert"
          realm="freebox"
          isMobile={isMobile || isTablet}
          isEligibilityCTA={false}
        />
        <PlausibleProvider
          domain={domain}
          customDomain={configurationsService.PLAUSIBLE_DOMAIN}
          selfHosted={true}
          trackLocalhost={true}
          taggedEvents={true}
          enabled={true}
        >
          <ContextProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ContextProvider>
        </PlausibleProvider>
        <Footer project="free-transfert" mentions={[]} extraMentions={[]} />
      </section>
    </>
  )
}

export default App
