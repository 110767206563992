const configurationServiceKeys = ['BACK_URL', 'APM_SERVER', 'FORCE_NON_SUBSCRIBER', 'PLAUSIBLE_DOMAIN'] as const
type ConfigurationServiceKey = (typeof configurationServiceKeys)[number]

class ConfigurationsService {
  constructor() {
    this.ensureValues(['BACK_URL', 'APM_SERVER', 'FORCE_NON_SUBSCRIBER', 'PLAUSIBLE_DOMAIN'])
  }

  get BACK_URL() {
    return this.getValue('BACK_URL')
  }

  get APM_SERVER() {
    return this.getValue('APM_SERVER')
  }

  get FORCE_NON_SUBSCRIBER() {
    return this.getValue('FORCE_NON_SUBSCRIBER') === 'true'
  }

  get PLAUSIBLE_DOMAIN() {
    return this.getValue('PLAUSIBLE_DOMAIN')
  }

  private getValue(key: ConfigurationServiceKey, throwOnMissing = true): string {
    const environmentValue = typeof window !== 'undefined' ? (window as any)._env_[key] : process.env[key]

    if (environmentValue === undefined && throwOnMissing) {
      throw new Error(`config error - missing env.${key}`)
    }

    return environmentValue ?? ''
  }

  private ensureValues(keys: ConfigurationServiceKey[]) {
    keys.forEach((k) => this.getValue(k, true))
    return this
  }
}

export const configurationsService = new ConfigurationsService()
