import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { IFile } from '@/services/fetchers/common'
import { fetchTransfer } from '@/services/fetchers/backend/transfers/fetchTransfer'
import { downloadFile } from '@/services/fetchers/backend/files/downloadFile'
import { ARCHIVE_NAME, STATUS_CODE } from '@/shared/constants'
import ResponsiveLayout from '@/components/templates/Shared/ResponsiveLayout/ResponsiveLayout'
import Message, { EMessageType } from '@/components/ui/Message/Message'
import Download from '@/components/templates/Download/Download'
import PasswordForm from '@/components/templates/Download/PasswordForm'
import { AppContext } from '@/shared/contexts/AppContext'
import { trackEvent } from '@/utils/tracking'
import { Spinner } from '@transverse/digitalsystems-library'
import Head from 'next/head'

type DownloadFileHandlerType = {
  file: IFile | null
  isZipDownload?: boolean
}

enum Status {
  LOADING,
  PASSWORD,
  DL_MULTIPLE,
  DL_UNIQUE,
  NOT_FOUND
}

const DownloadPage = () => {
  const [status, setStatus] = useState<Status>(Status.LOADING)
  const [files, setFiles] = useState<IFile[]>([])
  const [zip, setZip] = useState<IFile | null>({})
  const [passwordForm, setPasswordForm] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const { setError, setPassword } = useContext(AppContext)

  const router = useRouter()
  const { key } = router.query

  useEffect(() => {
    if (key) {
      fetchFiles({ password: passwordForm })
    }
  }, [key])

  const fetchFiles = async ({ fromPasswordForm = false, password }) => {
    setStatus(Status.LOADING)
    const { data, status, error } = await fetchTransfer({ transferKey: key?.toString() ?? '', password })

    if (status === STATUS_CODE.UNAUTHORIZED) {
      setStatus(Status.PASSWORD)

      if (fromPasswordForm) {
        setErrorMessage("Le mot de passe n'est pas valide")
      }
    } else if (status === STATUS_CODE.NOT_FOUND) {
      setStatus(Status.NOT_FOUND)
    } else if (error) {
      setError({ name: error.message })
    } else {
      setFiles(data?.files ?? [])
      setZip(data?.zip ?? null)
      setStatus(data?.files && data?.files.length > 1 ? Status.DL_MULTIPLE : Status.DL_UNIQUE)
    }
  }

  const downloadFileHandler = async ({ file, isZipDownload = false }: DownloadFileHandlerType) => {
    if (!file) {
      return
    }

    const response = await downloadFile({
      transferKey: key?.toString() ?? '',
      path: file.path ?? '',
      password: passwordForm
    })

    if (response.data?.url) {
      const fileName = isZipDownload ? ARCHIVE_NAME : file.path || ''
      const link = document.createElement('a')

      trackEvent({ category: 'download', action: 'download', name: isZipDownload ? 'zip' : 'file' })

      link.href = response.data?.url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  }

  const bottomActionParams = useMemo(() => {
    const button = () => {
      switch (status) {
        case Status.PASSWORD:
          return {
            label: 'Valider',
            onClick: () => fetchFiles({ fromPasswordForm: true, password: passwordForm })
          }
        case Status.NOT_FOUND:
        case Status.LOADING:
          return {
            label: 'Transférer des fichiers',
            onClick: () => router.push('/')
          }
        case Status.DL_UNIQUE:
          return { label: 'Télécharger', onClick: () => downloadFileHandler({ file: files[0] }) }
        default:
          const isZipGenerated = zip !== null && Object.keys(zip).length
          return {
            label: isZipGenerated ? 'Télécharger tout au format zip' : 'Archive en cours de génération',
            onClick: () => downloadFileHandler({ file: zip, isZipDownload: true }),
            disabled: !isZipGenerated
          }
      }
    }

    return {
      buttons: [button()]
    }
  }, [status, passwordForm])

  const component = useMemo(() => {
    switch (status) {
      case Status.PASSWORD:
        return (
          <PasswordForm
            password={passwordForm}
            setPassword={setPasswordForm}
            onSubmit={() => fetchFiles({ fromPasswordForm: true, password: passwordForm })}
            message="Veuillez saisir le mot de passe que l’on vous a communiqué pour télécharger les fichiers."
            error={errorMessage}
          />
        )
      case Status.NOT_FOUND:
        return <Message type={EMessageType.warning} title="Le transfert est introuvable !" />
      case Status.LOADING:
        return (
          <div className="flex h-full justify-center items-center">
            <Spinner size={90} className="text-blue" />
          </div>
        )
      default:
        return (
          <Download
            files={files}
            onDownload={(file) => downloadFileHandler({ file })}
            downloadKey={key?.toString() ?? ''}
          />
        )
    }
  }, [status, passwordForm, files, errorMessage])

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Head>
      <ResponsiveLayout leftBlock={component} bottomActionParams={bottomActionParams} />
    </>
  )
}

export default DownloadPage
