declare global {
  interface Window {
    plausible: any
  }
}

export type TrackingType = {
  category: string
  action: string
  name: string
}

export const trackEvent = ({ category, action, name }: TrackingType) => {
  if (typeof window !== 'undefined') {
    window.plausible =
      window.plausible ||
      function () {
        ;(window.plausible.q = window.plausible.q || []).push(arguments)
      }

    window.plausible(`${category}_${action}`, { props: { value: name } })
  }
}
